import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import 'antd/dist/reset.css';
import './locals/index.js'
import App from './App.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // 开启严格模式会双重渲染
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);

