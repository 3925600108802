import { lazy } from "react";
const BasicLayout = lazy(() => import("@containers/BasicLayout")); 
const Home = lazy(() => import("@containers/Home"));
const ProductsAndServices = lazy(() => import("@containers/ProductsAndServices"));
const ComplianceKnowledge = lazy(() => import("@containers/ComplianceKnowledge"));
const QAPage = lazy(() => import("@containers/QAPage"));
const ContactUs = lazy(() => import("@containers/ContactUs"));
const Datenschutz = lazy(() => import("@containers/Datenschutz"));
const Impressum = lazy(() => import("@containers/Impressum"));
const AuthorizedAgentCertification = lazy(() => import("@containers/AuthorizedAgentCertification"));
const DocCertification = lazy(() => import("@containers/DocCertification"));

let routes = [
  {
    path: "/",
    element: <BasicLayout />,
    children: [
      { index: true, element: <Home /> },
     
      {
        path: '/products_and_services',
        element: <ProductsAndServices />
      },
      
      {
        path: '/compliance_knowledge',
        element: <ComplianceKnowledge/>,
        
      },
      {
        path: '/q_a',
        element: <QAPage/>,
        
      },
      {
        path: '/contact_us',
        element: <ContactUs/>,
        
      },
      {
        path:  "/Datenschutz",
        element: <Datenschutz/>,
        
      },
      {
        path: '/impressum',
        element: <Impressum/>,
        
      },
      {
        path: '/auth_agent_certification',
        element: <AuthorizedAgentCertification/>,
      },
      {
        path: '/doc_certification',
        element: <DocCertification/>,
      }
      // { path: "*", element: <Search /> },
    ],
  }
];

export default routes;