import ComplianceKnowledgeDe from './ComplianceKnowledge/de'

// zh.js
export default {
  common: {
    lang_de: "Deutsch",
    lang_zh: "Chinesisch",
    lang_en: "English"
  },
  home: {
    Menulabel: "Startseite",
    Dienstleistung:'Dienstleistung',
    bannerTitle1:"EPR-Einhaltungsdienste",
    bannerSubTitle1: "WEEE / Recycling / Verpackungsgesetz",
    bannerTitle2: "Batteriegesetz Europäische Lösungen",
    bannerSubTitle2: "EPR / Europäische Bevollmächtigung / Energieprüfdienste",
    coreBusinessName1: "WEEE",
    coreBusinessDec1:
      "Registrierung von Elektro- und Elektronikaltgeräten für Recyclingdienste",
    coreBusinessName2: "Verpackungsgesetz",
    coreBusinessDec2:
      "Registrierung der Dienstleistung zur Entsorgung von Verpackungsabfällen",
    coreBusinessName3: "Batteriegesetz",
    coreBusinessDec3: "Registrierter Recyclingdienst für Batterien",
    coreBusinessName4: " EPR-Konformitätskontrolle",
    coreBusinessDec4: "Erstellung von Lösungen für EPR",
    coreBusinessName5: "Bevollmächtigter",
    coreBusinessDec5: "EU |UK |Deutschland Bevollmächtigter ElektroG",
    coreBusinessName6: "Energieprüfdienste",
    coreBusinessDec6:
      "Registrierung von Energieeffizienzmarken, Energieeffizienzprüfungsdienste",
  },
  productsAngServices: {
    Menulabel: "Produkte und Dienstleistungen",
    btnName:'Ein Mausklick und fertig!',
    priceLabel:'Servicepreis',
    originalPriceLabel:'Originalpreis',
    includeServic:'Inkludierte Leistungen',
    typeLabel:'Verfügbare Länder',
    timeLabel:'Bearbeitungsdauer',

    servicesName1:'WEEE Full-Service',
    servicesPrice1: "4500",
    servicesOriginalPrice1: "4800",
    servicesTime1: "6-12 Wochen",
    servicesDec1: "Registrierung bei den Behörden. Die Meldung bei den Behörden für ein Kalenderjahr. Die Grundgebühren für das Recycling von Elektrogeräten für ein Kalenderjahr. Exklusiver Service durch unseren eins-zu-eins Kundensupport Hosting-Services im Butler-Stil und Unterstützung beim Hochladen der notwendigen Zertifikate auf die jeweiligen Plattformen.",
    servicesType1: "WEEE, Deutschland",

    servicesName2:'VerpackG Full-Service',
    servicesPrice2: "499",
    servicesOriginalPrice2: "599",
    servicesTime2: "2～5 Arbeitstage",
    servicesDec2: "Registrierung bei den Behörden. Die Meldung bei den Behörden für ein Kalenderjahr. Die Grundgebühren für das Recycling von Elektrogeräten für ein Kalenderjahr. Exklusiver Service durch unseren eins-zu-eins Kundensupport Hosting-Services im Butler-Stil und Unterstützung beim Hochladen der notwendigen Zertifikate auf die jeweiligen Plattformen.",
    servicesType2: "VerpackG, Deutschland",

    servicesName3:'BattG Full-Service',
    servicesPrice3: "3080",
    servicesOriginalPrice3: "3580",
    servicesTime3: "6-12 Wochen",
    servicesDec3: "Registrierung bei den Behörden. Die Meldung bei den Behörden für ein Kalenderjahr. Die Grundgebühren für das Recycling von Elektrogeräten für ein Kalenderjahr. Exklusiver Service durch unseren eins-zu-eins Kundensupport Hosting-Services im Butler-Stil und Unterstützung beim Hochladen der notwendigen Zertifikate auf die jeweiligen Plattformen.",
    servicesType3: "BattG, Deutschland",
  },
  complianceknowledge: {
    MenulabelPC: "Konformitätskontrolle",
    Menulabel: "Konformitäts-kontrolle",
    ...ComplianceKnowledgeDe
  },
  contact: {
    Menulabel: "Kontakt",
    title: 'Über uns',
    adresse:'Adresse',
    postleitzahl:'Postleitzahl',
    kontakt:'Kontakt',
    eMail:'E-Mail',
    name:'Name',
    Grund:'Grund',
    submit:'Senden',
    cancel:'Abbrechen',
    apexDeDec: 'APEX CE Specialists GmbH ist ein professioneller Dienstleister, der sich auf die Einhaltung europäischer Produktnormen spezialisiert hat. Das Unternehmen engagiert sich dafür, grenzüberschreitenden Verkäufern, die nach Europa verkaufen, umfassende Produktkonformitätslösungen anzubieten. Zu den Kerngeschäftsbereichen gehören WEEE-Reporting- und Recycling-Services, Berichterstattung und Recycling nach Verpackungsgesetz, Berichterstattung und Recycling nach Batteriegesetz, europäischeund britische Agentenregistrierung, Energielabel-Registrierung, europäische Konformitätsprüfung und Zertiﬁzierung und vieles mehr. Der Geschäftsbereich des Unternehmens erstreckt sich auf mehr als 50 Länder und Regionen weltweit. Seit der Gründung hat APEX Zehntausenden von Kunden geholfen, umfassende und hochwertige EPR-Produktkonformitätsdienste bereitzustellen und ihnen dabei geholfen, sicher und schnell in den europäischen Markt zu expandieren.'

  },
  cookies:{
    title:'Datenschutzeinstellungen',
    text_p1:'Wir benötigen Ihre Zustimmung, bevor Sie unsere Website weiter besuchen können.',
    text_p2:'Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.',
    text_p3:'Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung. Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen.',
    Essenziell:'Essenziell',
    Essenziell_text:'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
    Statistiken:'Statistiken',
    Statistiken_text:'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.',

    Marketing:'Marketing',
    Marketing_text:'Marketing-Cookies werden von Drittanbietern oder Publishern verwendet, um personalisierte Werbung anzuzeigen. Sie tun dies, indem sie Besucher über Websites hinweg verfolgen.',

    ExterneMedien:'Externe Medien',
    ExterneMedien_text:'Inhalte von Videoplattformen und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.',
    btn1:'Alle akzeptieren',
    btn2:'Nur essenzielle Cookies akzeptieren'
},

  WEEE_question1:'Wie werden WEEE-Registrierungen klassifiziert?',
  WEEE_answers1_1:'Die WEEE-Registrierung erfolgt auf Basis von Marke und Kategorie. Wenn Sie zum Beispiel in Ihrem Amazon-Shop mehr als eine Marke und mehr als eine Produktkategorie haben, müssen Sie für jede weitere Marke und Kategorie eine zusätzliche Registrierung vornehmen.',
  WEEE_question2:'Wie werden WEEE-Registrierungen klassifiziert??',
  WEEE_answers2_1:'Die WEEE-Richtlinie definiert elektrische und elektronische Produkte als Geräte und Zubehörteile, die entweder mit elektrischem Strom oder elektromagnetischen Feldern arbeiten oder dazu bestimmt sind, elektrische Ströme und elektromagnetische Felder zu erzeugen, zu übertragen oder zu messen. Diese Produkte müssen eine Nennbetriebsspannung von nicht mehr als 1500 Volt Gleichstrom oder 1000 Volt Wechselstrom haben.',
  WEEE_question3:'In welche Kategorie fallen die Produkte der Verkäufer im Allgemeinen am häufigsten?',
  WEEE_answers3_1:'Aktuell ist die Kategorie Kleine Elektro- und Elektronikgeräte bei Verkäufern am beliebtesten.',
  WEEE_answers3_1:'Produkte in dieser Kategorie sind unter anderem LED-Taschenlampen, LED-Lichtleisten, Wecklichter, Schminkspiegel, Kameras, Wecker, Spielzeug, Adapter, elektronische Küchenwaagen, Drohnen für Haustiere, Reifendruckkontrollgeräte, kabellose Ladegeräte, Reiseladegeräte, Datenkabel, HDMI-Switches, Balance-Bikes, elektrische Mückenklatschen, Stereoanlagen, Kopfhörer, Nachtlichter, Lockenstäbe, elektrische Zahnbürsten, elektrische Rasierapparate, Staubsauger, Mückenschutzlampen und Bluetooth-Thermometer.',
  WEEE_question4:'Welche Länder verlangen eine WEEE-Zertifizierung?',
  WEEE_answers4_1:'Deutschland, das Vereinigte Königreich, Frankreich, Italien, Spanien, Schweden, die Niederlande, Dänemark, Portugal, Polen, Belgien, Finnland, Luxemburg, Österreich, Irland und weitere Länder verlangen eine WEEE-Zertifizierung.',
  WEEE_question5:'Kann ich eine WEEE-Registrierungsnummer für mehrere Geschäfte verwenden?',
  WEEE_answers5_1:'Nein, die WEEE-Registrierungsnummer gilt nur für ein Geschäft. Wenn Sie mehrere Geschäfte unter derselben Firma haben, müssen Sie jedes Geschäft separat für WEEE registrieren, auch wenn sie dieselben Produkte verkaufen.',
  WEEE_question6:'Was sind die Anforderungen an die Kennzeichnung von WEEE-Produktverpackungen?',
  WEEE_answers6_1:'Gemäß der neuen WEEE-Richtlinie müssen Produkte, die in den Geltungsbereich der Richtlinie fallen, mit dem WEEE-Etikett gekennzeichnet werden. Die Kennzeichnung muss den folgenden Spezifikationen entsprechen:',
  WEEE_answers6_2:'A) Die Höhe der schwarzen Linie am unteren Ende des Etiketts darf nicht weniger als 0,3 A oder 1 mm betragen.',
  WEEE_answers6_3:'B) Die Höhe des gesamten Etiketts darf nicht weniger als 7 mm betragen (d.h. A = 3,33 mm).',
  WEEE_answers6_4:'C) Wenn es aus besonderen Gründen nicht möglich ist, das Produkt zu kennzeichnen, muss die Kennzeichnung auf der Produktverpackung oder in der Gebrauchsanweisung wiedergegeben werden.',
  WEEE_answers6_5:'D) Die Kennzeichnung muss deutlich, auffällig und nicht abriebfest sein.',
  WEEE_question7:'Welche besonderen Anforderungen gelten für die Anbringung der Kennzeichnung auf den Abfallbehältern der EU-WEEE-Richtlinie?',
  WEEE_answers7_1: 'Gemäß der neuen WEEE-Richtlinie müssen Produkte, die in den Geltungsbereich der Richtlinie fallen, mit dem WEEE-Etikett gekennzeichnet werden. Dabei gelten folgende Anforderungen:',
  WEEE_answers7_2:'A) Das Produktlogo muss an einer gut sichtbaren Stelle angebracht werden.',
  WEEE_answers7_3:'B) Bei tragbaren Produkten sollte das Etikett ohne Werkzeug sichtbar sein, es sei denn, die Verwendung von Werkzeug ist aus Gesundheits- und Sicherheitsgründen im Rahmen anderer Richtlinien erforderlich, um die Abdeckung zu öffnen.',
  WEEE_answers7_4:'C) Lässt die Größe oder Funktion des Produkts den Aufdruck des Logos auf dem Produkt nicht zu, muss es auf der Verpackung, der Gebrauchsanleitung oder der Garantiekarte angebracht werden.',



  PACKAGING_question1:'Was sind die Folgen, wenn ich mich nicht für das deutsche Verpackungsgesetz registriere?',
  PACKAGING_answers1_1: '(1) Wenn Sie Ihren Herstellerpflichten nicht nachkommen (u. a. Registrierung bei der ZSVR, Meldung von Verpackungsinformationen und Zahlung eines Meldeentgelts an das duale System), riskieren Sie ein Verkaufsverbot und ein Bußgeld von bis zu 100.000 Euro von der Regulierungsbehörde.',
  PACKAGING_answers1_2: '(2) Wenn Sie ab dem 1. Juli 2022 über eine EU-E-Commerce-Plattform Waren nach Deutschland verkaufen, muss die EU-E-Commerce-Plattform prüfen, ob Sie in LUCID registriert sind. Andernfalls wird die EU-E-Commerce-Plattform den Verkauf von nicht konformen Waren aussetzen.',
  PACKAGING_answers1_3: '(3) Mit der Registrierung bei LUCID erklären Sie gegenüber der EU-E-Commerce-Plattform und der Öffentlichkeit, dass Sie alle Verpflichtungen zur Herstellerverantwortung für die Verpackung von in Deutschland verkauften Waren erfüllt haben, einschließlich der Unterzeichnung der Systemregistrierungsvereinbarung, der Zahlung der Systemregistrierungsgebühr an den Betreiber des dualen Systems und der Erfüllung Ihrer Meldepflichten.',
  PACKAGING_answers1_4: '(4) Das Register der registrierten Hersteller ist eine öffentliche Information und soll es jedermann ermöglichen, nach einem bestimmten Hersteller, einer Marke, einer Herkunft, einer LUCID-Registriernummer oder einer Steuernummer zu suchen. Auf diese Weise kann überprüft werden, ob der Hersteller die entsprechenden Verpflichtungen erfüllt hat.',
  PACKAGING_question2:'Wer muss sich für das deutsche Verpackungsgesetz registrieren?',
  PACKAGING_answers2_1:'Sie müssen sich registrieren, wenn Ihre Waren irgendeine Art von Verpackung haben (einschließlich Umverpackungen für die Lieferung an den Endabnehmer), da Sie dann als "Hersteller" im Sinne der erweiterten Herstellerverantwortung gelten. Das deutsche Verpackungsgesetz gilt nicht nur für den Hersteller, sondern auch für andere Warenkategorien. Kurz gesagt: Sie haften nach dem deutschen Verpackungsgesetz, wenn Sie eine der folgenden Möglichkeiten erfüllen:',
  PACKAGING_answers2_2: '(i) Wenn Sie in Deutschland Waren herstellen, die der erweiterten Herstellerverantwortung unterliegen, und diese in Deutschland verkaufen.',
  PACKAGING_answers2_3:'(ii) Wenn Sie Waren nach Deutschland importieren, die den Anforderungen der erweiterten Herstellerverantwortung unterliegen.',
  PACKAGING_answers2_4:'(iii) Wenn Sie Waren in Deutschland verkaufen, die der erweiterten Herstellerverantwortung unterliegen, und Sie nicht in Deutschland ansässig sind. Dies ist die häufigste Kategorie bei grenzüberschreitenden Verkäufern.',
  PACKAGING_question3:'Welche Materialien werden für die Verpackung verwendet?',
  PACKAGING_answers3_1: '1.Glas (farblos oder getönt, ohne Deckel).',
  PACKAGING_answers3_2:'2.Papier, Karton.',
  PACKAGING_answers3_3:'3.Eisenhaltiges Metall (magnetisch, z. B. Glasflaschenverschlüsse oder Deckel von Konservengläsern).',
  PACKAGING_answers3_4:'4.Aluminium (nicht magnetisch, z. B. Cremetuben).',
  PACKAGING_answers3_5:'5.Kunststoffe (PET, PP, PE, usw.; Verbundstoffe).',
  PACKAGING_answers3_6:'6.Andere Verbundverpackungen: (1) Konforme Materialien, die sich nicht von Hand auseinanderziehen lassen, (2) weniger als 95 Gewichtsprozent jeder Materialart und (3) deren Inhalt keine Getränke oder Lebensmittel sind.',
  PACKAGING_answers3_7:'7.Andere Materialien (z. B. Baumwolle, Holz, Naturkork, Gummi, Bast, Keramik).',
  PACKAGING_answers3_8:'8.Wenn Sie Frischhaltefolie in der Verpackung, im Füllmaterial oder in anderen Bestandteilen Ihrer Waren (z. B. Klebeband, Adressaufkleber oder Paketschnüre) verwenden, gilt diese ebenfalls als Verpackungsmaterial im Sinne des Verpackungsgesetzes.',
  PACKAGING_question4: 'Müssen sich auch Selbstabholer für das Verpackungsgesetz registrieren?',
  PACKAGING_answers4_1: 'Ja, Selbstabholer müssen die Verpackungsvorschriften einhalten und sich für das Verpackungsgesetz registrieren lassen, solange sie vor Ort präsent sind.',
  PACKAGING_answers4_2:'Auch grenzüberschreitende E-Commerce-Plattformen bestätigen, dass alle Verkäufer die Verpackungsvorschriften einhalten müssen, unabhängig davon, ob Sie die Logistik der Plattform oder die des Verkäufers für den Selbstversand nutzen.',
  PACKAGING_question5: 'Ist die Verpackung von Spielzeugprodukten durch das Gesetz geregelt?',
  PACKAGING_answers5_1:'Ja, solange eine Verpackung verwendet wird, unabhängig von der Art des Produkts, fällt sie unter dieses Gesetz.',
  PACKAGING_question6:'Welche Bedeutung hat der Haftungsausschluss im Verpackungsgesetz?',
  PACKAGING_answers6_1:'Bevor Waren auf den Markt gebracht werden, müssen sich Importeure registrieren lassen. Die übermittelten Daten werden geschätzt und bis zum 15. März des Folgejahres muss der Importeur eine Vollständigkeitserklärung abgeben, aus der hervorgeht, wie viel Verpackung im Vorjahr tatsächlich verwendet wurde. Wenn im Vorjahr weniger Verpackungen verwendet wurden, kann auf diese Erklärung verzichtet werden. Bitte beachten Sie, dass es zum Zeitpunkt der Anmeldung keine Befreiung gibt. Auch wenn nur ein einziges verpacktes Produkt eingeführt wird, muss die Verpackung registriert werden.',
  PACKAGING_question7:'Muss ich das Etikett nach der Registrierung gemäß dem Verpackungsgesetz bedrucken?',
  PACKAGING_answers7_1:'Ein Aufdruck ist nach dem Verpackungsgesetz nicht zwingend vorgeschrieben (er kann aufgedruckt werden oder nicht). Um auf Nummer sicher zu gehen, wird jedoch empfohlen, das Recycling-Symbol auf das Kartonetikett zu drucken.',
  PACKAGING_question8:'Ist die Verpackung aller Produkte durch das Gesetz geregelt?',
  PACKAGING_answers8_1: 'Es gibt Ausnahmen, aber im Allgemeinen gelten diese nicht für Produkte, die von chinesischen Verkäufern auf Amazon verkauft werden. Eine Ausnahme wären zum Beispiel Pestizid Flaschen, die mit giftigen Stoffen kontaminiert sind.',
  PACKAGING_question9:'Müssen Verkäufer ihre Verpackungen ändern, nachdem sie sich für das Verpackungsgesetz registriert haben?',
  PACKAGING_answers9_1: 'Das neue Verpackungsgesetz stellt keine neuen Anforderungen an die Verpackung selbst (z. B. chemische, physikalische und Kennzeichnungsanforderungen). Wenn Ihr Verpackungsmaterial bereits den Anforderungen des ursprünglichen Verpackungsgesetzes entspricht, müssen Sie Ihr Verpackungsmaterial nicht ändern, aber Sie müssen sich registrieren lassen.',
  PACKAGING_question10:'Was bedeutet die Befreiung vom Verpackungsgesetz?',
  PACKAGING_answers10_1:'Bevor Waren auf den Markt gebracht werden, müssen sich Importeure registrieren lassen. Die übermittelten Daten sind bereits festgelegt und bis zum 15. März des Folgejahres muss der Importeur eine Vollständigkeitserklärung abgeben, in der er angibt, wie viel Verpackung im Vorjahr tatsächlich verwendet wurde. Wenn im Vorjahr weniger Verpackungen verwendet wurden, kann auf die Erklärung verzichtet werden. Bitte beachten Sie, dass es keine Ausnahme vom Verpackungsgesetz gibt. Auch wenn Sie nur ein einziges Verpackungsprodukt importieren, müssen Sie sich für das Verpackungsgesetz registrieren lassen.',
  PACKAGING_question11:'Welche Informationen benötige ich für die Registrierung nach dem deutschen Verpackungsgesetz?',
  PACKAGING_answers11_1:'Sie benötigen Ihren Gewerbeschein, Ihre Umsatzsteuernummer, Ihr Impressum, Ihre Telefonnummer, Ihre E-Mail-Adresse sowie Ihre Markenangaben.',


  BATTERY_question1: 'Was ist das deutsche Batteriegesetz?',
  BATTERY_answers1_1:'Das deutsche Batteriegesetz (BattG) setzt die europäische Richtlinie 2006/66/EG in nationales Recht um und regelt das Recycling von Batterien und Akkumulatoren. Verkäufer von Batterien und Akkumulatoren auf dem deutschen Markt sind verpflichtet, die verkauften Batterien zu recyceln. Das Gesetz gilt für alle Arten von Batterien, unabhängig von Form, Größe, Gewicht, Materialzusammensetzung oder Verwendung.',
  BATTERY_question2:'Wozu dient das deutsche Batteriegesetz?',
  BATTERY_answers2_1:'Das Gesetz regelt das Recycling aller Batterien und Akkumulatoren in Deutschland. Verkäufer sind daher verpflichtet, in Deutschland in Verkehr gebrachte Batterien und Akkumulatoren zurückzunehmen und ein Recyclingunternehmen zu beauftragen, das gebrauchte Batterien nach bestimmten Umweltstandards entsorgt.',
  BATTERY_question3: 'Wer ist zur Registrierung verpflichtet?',
  BATTERY_answers3_1:'Jeder Verkäufer, der in Deutschland Produkte verkauft, die Batterien oder Akkumulatoren enthalten, muss sich gemäß dem deutschen Batteriegesetz registrieren lassen und ist für die Verwertung der Abfälle verantwortlich, die nach dem Inverkehrbringen des Produkts entstehen.',
  BATTERY_question4: 'Welche Produkte fallen unter das Batteriegesetz?',
  BATTERY_answers4_1:'Das deutsche Batteriegesetz gilt für alle Arten von Batterien und Akkumulatoren,',
  BATTERY_answers4_2:'einschließlich solcher in Produkten wie elektrischem Spielzeug, ',
  BATTERY_answers4_3:'Mobiltelefonen und anderen Geräten mit eingebauten Batterien.',
  BATTERY_answers4_4:'Standardformat für interne batteriegesetzliche Registrierungsnummern',
  BATTERY_answers4_5:'Das Format der Registrierungsnummer nach dem Batteriegesetz',
  BATTERY_answers4_6:'Das Format der Registrierungsnummer nach dem Batteriegesetz lautet: Batt-Reg.-Nr. DE XXXXXXXX.',
  BATTERY_answers4_7:'Die Registrierungsnummer ist für ein Kalenderjahr gültig, d.h. wenn die Registrierung im Jahr 2022 erfolgreich ist, muss sie Anfang 2023 erneuert werden.',
  BATTERY_question5: 'Was sind die schwerwiegenden Folgen für Verkäufer, die sich nicht für das deutsche Batteriegesetz registrieren lassen?',
  BATTERY_answers5_1:'Wenn Sie sich nicht für das deutsche Batteriegesetz registrieren, gebrauchte Batterien nicht recyceln und die Anzahl der Verkäufe nicht regelmäßig melden, müssen Sie gemäß dem deutschen Batteriegesetz, der offiziellen Bekanntmachung von Amazon und dem deutschen Gesetz gegen den unlauteren Wettbewerb mit den folgenden schwerwiegenden Konsequenzen rechnen:',
  BATTERY_answers5_2:'1.Ihr Angebot wird gelöscht und der Link zum Produkt wird entfernt.',
  BATTERY_answers5_3:'2.Die Ware wird bei der Ankunft in Deutschland vom Zoll beschlagnahmt.',
  BATTERY_answers5_4:'3.Beschlagnahmung aller vom Verkäufer in Deutschland erzielten Gewinne.',
  BATTERY_answers5_5:'4.Eine Geldstrafe von bis zu 100.000 Euro.',
  BATTERY_question6: 'Wie sollten Verkäufer im grenzüberschreitenden elektronischen Handel das Batteriegesetz beachten?',
  BATTERY_answers6_1: 'Verkäufer im grenzüberschreitenden elektronischen Handel müssen sich gemäß dem deutschen Batteriegesetz registrieren lassen, gebrauchte Batterien recyceln und die Anzahl der verkauften Batterien regelmäßig melden. Andernfalls müssen sie mit schwerwiegenden Konsequenzen gemäß dem deutschen Batteriegesetz, der offiziellen Amazon-Meldung und dem deutschen Gesetz gegen den unlauteren Wettbewerb rechnen. Die Konsequenzen umfassen:',
  BATTERY_answers6_2: '1.Erforderliche Registrierung des Batteriegesetzes vor dem Inverkehrbringen des Produkts und Angabe zum Recycling.',
  BATTERY_answers6_3: '2.Das Batteriegesetz-Recyclinglogo muss auf dem Produkt selbst aufgedruckt und dauerhaft sichtbar und lesbar sein, nachdem das Zertifikat erhalten wurde.',
  BATTERY_answers6_4: '3.Verbrauchte Batterien müssen gesammelt und vorschriftsmäßig entsorgt werden.',
  BATTERY_answers6_5: '4.Regelmäßige Meldung der Anzahl der verkauften Batterien gemäß der amtlichen Erklärung.',
  BATTERY_question7: 'Ist es möglich, eine einzige Batterie-Registrierungsnummer für mehrere Geschäfte zu registrieren?',
  BATTERY_answers7_1:'Nein, die Batterie-Registrierungsnummer entspricht dem Geschäft und es gibt nur eine Registrierungsnummer für ein Geschäft. Wenn ein Unternehmen mehrere Geschäfte hat, müssen sie separat für das Batteriegesetz registriert werden, auch wenn sie die gleichen Produkte verkaufen.',
  BATTERY_question8: 'Was sind die Anforderungen an die Kennzeichnung der Außenverpackung von Produkten gemäß dem Batteriegesetz?',
  BATTERY_answers8_1:'Gemäß der neuen Batteriegesetz-Richtlinie müssen Produkte, die in den Geltungsbereich der Richtlinie fallen, mit dem Batteriegesetz-Etikett gekennzeichnet werden. Die Kennzeichnung muss den folgenden Spezifikationen entsprechen:',
  BATTERY_answers8_2:'A) Die Höhe der schwarzen Linie unter dem H darf nicht weniger als 0,3A oder 1MM betragen.',
  BATTERY_answers8_3: 'B) Die Höhe des gesamten Schildes darf nicht weniger als 7MM betragen (d.h. A=3,33MM).',
  BATTERY_answers8_4: 'C) Wenn es aus besonderen Gründen nicht möglich ist, das Produkt zu kennzeichnen, muss die Kennzeichnung auf der Produktverpackung oder in der Gebrauchsanweisung wiedergegeben werden.',
  BATTERY_answers8_5:  'D) Die Kennzeichnung muss deutlich, auffällig und verschleißfest sein.',
  BATTERY_question9:'Welche besonderen Anforderungen stellt die EU-Batterierichtlinie an die Kennzeichnung von Abfallbehältern?',
  BATTERY_answers9_1:'A) Die Kennzeichnung des Produkts muss an einer gut sichtbaren Stelle angebracht werden.',
  BATTERY_answers9_2:'B) Bei tragbaren Produkten muss die Kennzeichnung sichtbar sein, ohne dass Werkzeuge zum Abnehmen des Deckels verwendet werden müssen. Es sei denn, dass die Verwendung von Werkzeugen aus Gründen des Gesundheitsschutzes und der Sicherheit im Rahmen anderer Richtlinien zum Öffnen des Deckels erforderlich ist.',
  BATTERY_answers9_3:'C) Wenn es aufgrund der Größe oder der Funktion des Produkts nicht möglich ist, das Kennzeichnungsetikett auf das Produkt selbst aufzubringen, muss es auf der Verpackung, in der Gebrauchsanweisung oder auf dem Garantieschein des Produkts angebracht werden.',
  BATTERY_answers9_4:'Diese Anforderungen gewährleisten, dass die Kennzeichnung der Batterien und Akkumulatoren leicht erkennbar und verständlich ist und die ordnungsgemäße Entsorgung durch die Verbraucher erleichtert wird.',

  EPR_question1: 'Was ist EPR?',
EPR_answers1_1:'EPR steht für "erweiterte Herstellerverantwortung" und ist eine Umweltpolitik, die von den Herstellern verlangt, die Verantwortung für den gesamten Lebenszyklus der von ihnen auf den Markt gebrachten Waren zu übernehmen, d.h. vom Design bis zum Ende des Lebenszyklus (einschließlich der Abfallsammlung und -entsorgung). Die verantwortliche Partei muss die Umweltauswirkungen ihrer Waren während des gesamten Lebenszyklus verringern.',
EPR_question2: 'Ist EPR nur in Deutschland und Frankreich verfügbar?',
EPR_answers2_1:'Nein, die überwiegende Mehrheit der europäischen Länder, einschließlich EU-Mitgliedstaaten, hat Vorschriften zur Kontrolle der EPR erlassen. Derzeit ist für europäische grenzüberschreitende Verkäufer eine Registrierung in Deutschland, Frankreich, Spanien und Österreich obligatorisch. In Zukunft wird die Registrierung auch in anderen Ländern wie dem Vereinigten Königreich, Italien und den Niederlanden erforderlich sein.',
EPR_question3: 'Muss ich mich für EPR registrieren, wenn ich ein Verkäufer bin, der selbst ausliefert?',
EPR_answers3_1: 'Ja, Amazon muss bestätigen, dass alle Verkäufer die Anforderungen der erweiterten Herstellerverantwortung (EPR) erfüllen, unabhängig davon, ob Sie FBA nutzen oder selbst ausliefern.',
EPR_question4: 'Wer muss sich für EPR registrieren?',

EPR_answers4_1:'Ursprünglich war EPR für "Hersteller" gedacht, aber auch andere Parteien können betroffen sein. ',
EPR_answers4_2:'Die EPR-Registrierung betrifft lokale Hersteller,',
EPR_answers4_3:'lokale Importeure und lokale Verkäufer über eine Plattform, die Waren verkaufen,',
EPR_answers4_4:'die den Anforderungen der erweiterten Herstellerverantwortung im jeweiligen Land unterliegen und nicht in diesem Land ansässig sind.',
EPR_answers4_5:' Im Grunde genommen fallen alle Verkäufer in die dritte Kategorie von Herstellern.',


EPR_question5:'Gibt es eine einheitliche EPR-Registrierungsnummer?',
EPR_answers5_1:'Nein, EPR-Registrierungsnummern sind nicht standardisiert. Es gibt unterschiedliche Produktkategorien, Registrierungsanforderungen, -verfahren und Zulassungsstellen in verschiedenen Ländern, und die daraus resultierenden Registrierungsnummern sind unterschiedlich.',
EPR_question6: 'Für welche Produkte ist eine EPR-Registrierung erforderlich?',
EPR_answers6_1:'In Deutschland sind Verpackungen, Elektro- und Elektronikgeräte (WEEE) sowie Batterien betroffen. ',
EPR_answers6_2:'In Frankreich sind Verpackungen, Elektro- und Elektronikgeräte (WEEE), Batterien, Möbel, Textilien, Papier, Reifen und andere Produkte betroffen. ',
EPR_answers6_3:'In Österreich sind Verpackungen, Elektro- und Elektronikgeräte (WEEE), Batterien und Einwegkunststoffe betroffen. ',
EPR_answers6_4:'In Spanien sind Verpackungen, Elektro- und Elektronikgeräte (EEE), Batterien, Schmierstoffe und Industrieöle, Reifen und andere Produkte betroffen.',
EPR_question7: 'Was sind die Folgen einer Nichtregistrierung?',
EPR_answers7_1: 'Wenn ein Verkauf in einem EU-Land ohne EPR-Registrierung erfolgt, hat Amazon das Recht, das nicht konforme Produkt vom Markt zu nehmen.',
EPR_answers7_2: 'Wenn die französische EPR-Registrierung nicht gemeldet wird, haben die französischen Behörden das Recht, von grenzüberschreitenden Plattformen zu verlangen, dass sie Verkäufer, die auf ihren Plattformen verkaufen, auffordern, sich für die EPR zu registrieren und ihnen anweisen, den Verkauf einzustellen, bis sie ein EPR-Zertifikat und eine UIN-Nummer erhalten haben.',
EPR_answers7_3: 'Verkäufer, die gegen andere Verpflichtungen des französischen Umweltrechts verstoßen, werden mit folgenden Strafen belegt:',
EPR_answers7_4: '1.Eine Geldstrafe von bis zu 1 Million €.',
EPR_answers7_5: '2.Alle in Frankreich erzielten Einkünfte gelten als illegal und werden beschlagnahmt.',
EPR_answers7_6: '3.Bis zur Begleichung der Geldstrafe dürfen keine Verkäufe getätigt werden.',


AUTH_Agent_1: 'Authorized Products',
AUTH_Agent_productname: 'Product  name',
AUTH_Agent_registration_time: 'Registration time',
AUTH_Agent_trademark: 'Trademark',
AUTH_Agent_model: 'model',
AUTH_Agent_platform_product_uniqure_code: 'Platform/Product Unique Code',
AUTH_Agent_manufacturer: 'Manufacturer',
AUTH_Agent_Applicant_Company: 'Issuer',


DOC_Certification:'Declaration Of Conformity Authorized',
DOC_Instruction:'Instruction',
DOC_Standard:'Standard',


VALID: 'Valid',
INVALID: 'Invalid',
VIEWALL: 'View all',
FREEZE: '冻结',
DATAPROVIDE: 'J&P Accountants Authorized Registrant',
ADD_product_tips: 'Please add product information in a timely manner',
UNUSAL_MSG: 'Unprovided by Authorized Registrant',
UNUSAL_DOC_MSG: 'DOC not effective'
};
