import { Button, Modal, Checkbox, Col, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";
const CookieDialog = () => {
  console.log(localStorage.getItem('_coo1'))
  const [isModalOpen, setIsModalOpen] = useState(!(localStorage.getItem('_coo1') || false));
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const { t } = useTranslation();

  const handleOk = () => {
    localStorage.setItem("_coo1", true);
    setIsModalOpen(false);
  };
  const handleAllOk = () => {
    localStorage.setItem("_coo1", true);
    setChecked1(true)
    setChecked2(true)
    setChecked3(true)
    var t1 = window.setTimeout(function() {
      setIsModalOpen(false);
    },300)
    // window.clearTimeout(t1)
  };
  const onChange = (e,event) => {
    console.log(event)
    switch (event) {
      case 1:
        setChecked1(e.target.checked);
        break;
      case 2:
        setChecked2(e.target.checked);
        break;
      case 3:
        setChecked3(e.target.checked);
        break;
      default:
        break;
    }
  };
  

  return (
    <>
      <Modal
        title={t("cookies.title")}
        width={700}
        closable={false}
        open={isModalOpen}
        footer={[
          <Button key="1" type="primary" onClick={handleAllOk}>
            {t("cookies.btn1")}
          </Button>,
          <Button key="2" type="primary" onClick={handleOk}>
            {t("cookies.btn2")}
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <p>{t("cookies.text_p1")}</p>
            <p>{t("cookies.text_p2")}</p>
            <p>{t("cookies.text_p3")}</p>
          </Col>
          <Col span={12}>
            <p>
              <Checkbox defaultChecked disabled>
                {t("cookies.Essenziell")}
              </Checkbox>
              {t("cookies.Essenziell_text")}
            </p>
            <p>
              <Checkbox checked={checked1} onChange={(a)=>onChange(a,1)}>
                {t("cookies.Statistiken")}
              </Checkbox>
              {t("cookies.Statistiken_text")}
            </p>
            <p>
              <Checkbox checked={checked2} onChange={(a)=>onChange(a,2)}>
                {t("cookies.Marketing")}
              </Checkbox>

              {t("cookies.Marketing_text")}
            </p>
            <p>
              <Checkbox  checked={checked3} onChange={(a)=>onChange(a,3)}>
                {t("cookies.ExterneMedien")}
              </Checkbox>
              {t("cookies.ExterneMedien_text")}
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default CookieDialog;
