import ComplianceKnowledgeZh from './ComplianceKnowledge/zh'

// zh.js
export default {
    common:{
        lang_de:'Deutsch',
        lang_zh:'中文',
        lang_en: "English"
    },
    home:{
        Menulabel:'首页',
        Dienstleistung:'核心业务',
        bannerTitle1:"EPR合规服务",
        bannerSubTitle1: "WEEE / 电池法 / 包装法",
        bannerTitle2: "欧洲产品合规解决方案",
        bannerSubTitle2: "EPR / 欧代 / 能效",
        coreBusinessName1: "WEEE",
        coreBusinessDec1: "电子电器废弃物注册申报回收服务",
        coreBusinessName2: "包装法",
        coreBusinessDec2:  "外包装废弃物注册申报回收服务",
        coreBusinessName3: "电池法",
        coreBusinessDec3:"电池废弃物注册申报回收服务",
        coreBusinessName4: "产品合规",
        coreBusinessDec4: "定制化EPR跨境产品合规解决方案",
        coreBusinessName5:  "授权代表",
        coreBusinessDec5: "欧盟/英国/德国授权代理人业务",
        coreBusinessName6: "能效服务",
        coreBusinessDec6: "能效标签注册/能效检测服务",
        
    },
    productsAngServices:{
        Menulabel:'产品与服务',
        btnName:'一键办理',
        priceLabel:'价格',
        originalPriceLabel:'原价',
        includeServic:'包含服务',
        typeLabel:'类型',
        timeLabel:'下证时间',

        servicesName1:'WEEE服务',
        servicesPrice1: "4500",
        servicesOriginalPrice1: "4800",
        servicesTime1: "6-12周",
        servicesDec1: "官方机构注册登记服务、一自然年申报服务、一个自然年度电子电器基础回收费用、专属人工客服对接、管家式托管注册、协助上传证书至平台。",
        servicesType1: "德国WEEE",

        servicesName2:'包装法服务',
        servicesPrice2: "499",
        servicesOriginalPrice2: "599",
        servicesTime2: "2～5天",
        servicesDec2: "官方机构注册登记服务、一自然年申报服务、一个自然年度电子电器基础回收费用、专属人工客服对接、管家式托管注册、协助上传证书至平台。",
        servicesType2: "德国包装法",

        servicesName3:'电池法服务',
        servicesPrice3: "3080",
        servicesOriginalPrice3: "3580",
        servicesTime3: "6-12周",
        servicesDec3: "官方机构注册登记服务、一自然年申报服务、一个自然年度电子电器基础回收费用、专属人工客服对接、管家式托管注册、协助上传证书至平台。",
        servicesType3: "德国电池法",

    },
    complianceknowledge:{
        MenulabelPC:'合规知识',
        Menulabel:'合规知识',
        ...ComplianceKnowledgeZh
    },
    contact:{
        Menulabel:'联系我们',
        title: '关于我们',
        adresse:'联系地址',
        postleitzahl:'邮编',
        kontakt:'联系热线',
        eMail:'联系邮箱',
        name:'名字',
        Grund:'联系原因',
        submit:'确定',
        cancel:'取消',
        apexDeDec: 'apex-de 是专业从事欧洲产品合规的服务机构，致力于为销售到欧洲的跨境卖家提供一站式产品合规解决方案，核心业务是WEEE申报回收服务、包装法申报回收、电池法申报回收、欧代/英代注册、能效标签注册、欧洲合规检测认证等，业务范围覆盖到全球50多个国家与地区，自成立以来，已经帮助数万客户提供全面优质的EPR产品合规服务，助力客户快速安全拓展欧洲市场。'
    },
    cookies:{
        title:'隐私设置',
        text_p1:'在您继续浏览我们的网站之前，我们需要您的同意。',
        text_p2:'如果您未满 16 岁并希望同意志愿服务，您必须征得您的法定监护人的许可。',
        text_p3:'我们在我们的网站上使用 cookie 和其他技术。其中一些是必不可少的，而另一些则帮助我们改进本网站和您的体验。 可以处理个人数据（例如 IP 地址），例如 B. 用于个性化广告和内容或广告和内容测量。 您可以在我们的数据保护声明中找到有关数据使用的更多信息 。 您可以随时在“设置”下撤销或调整您的选择。',
        Essenziell:'基本的',
        Essenziell_text:'基本 cookie 启用基本功能，是网站正常运行所必需的。',
        Statistiken:'统计数据',
        Statistiken_text:'统计 cookie 以匿名方式收集信息。这些信息有助于我们了解访问者如何使用我们的网站。',
        Marketing:'营销',
        Marketing_text:'第三方或出版商使用营销 cookie 来显示个性化广告。他们通过跨网站跟踪访问者来做到这一点。',
        ExterneMedien:'外部媒体',
        ExterneMedien_text:'来自视频平台和社交媒体平台的内容默认被阻止。如果接受来自外部媒体的 cookie，则访问此内容不再需要手动同意。',
        btn1:'全部接受',
        btn2:'只接受必要的cookie'

    },
    WEEE_question1:'WEEE的注册如何分类?',
  WEEE_answers1_1:'WEEE注册是按一个品牌和一个类别来注册的。以亚马逊店铺为例，若该店铺下有多个品牌及多个产品类别，需要增加注册品牌和类别,此需要增加费用的，具体费用可以联系我们。',
  WEEE_question2:'新版WEEE指令监管的电子电气产品的定义是什么?',
  WEEE_answers2_1:'指令规定，依靠电流或电磁场工作或者以产生、传输和测量电流和电磁场为目的，额定工作电压为直流电不超过1500伏特、交流电不超过1000伏特的设备及配套产品被称为电子电气产品。',
  WEEE_question3:'WEEE产品分为六大类别，-般卖家产品以那个类别最多呢?',
  WEEE_answers3_1:'目前卖家产品以第五类小型电子电气设备类为最多。此类的常见产品有:',
  WEEE_answers3_1:'LED手电筒、LED灯条、唤醒灯J、化妆镜、摄像头、闹钟、玩具、转接头、厨房电子秤、无人机宠物电子用品、胎压仪、无线充电器、旅行充电器、数据线、HDMI SWITCH、平衡车、电蚊拍、音响、耳机、小夜灯、卷发棒、电动牙刷、电动剃须、吸尘器、灭蚊灯、蓝牙温度计。',
  WEEE_question4:'哪些国家需要进行WEEE认证?',
  WEEE_answers4_1:'德国、英国、法国、意大利、西班牙、瑞典、荷兰、丹麦、葡萄牙、波兰、比利时、芬兰、卢森堡、奥地利、爱尔兰等。',
  WEEE_question5:'WEEE注册号是否可以注册一个，多个店铺通用?',
  WEEE_answers5_1:'WEEE注册号是和店铺对应的。一个店铺只有一个注册号， -个注册号，也只能对应一个店铺。 -个公司下有多个店铺，就算是卖的产品一样,每个店铺也需要单独注册WEEE。',
  WEEE_question6:'WEEE产品外包装标识要求?',
  WEEE_answers6_1:'新WEEE指令要求产品在管控范围内的，需要贴WEEE标示，规格要求如下:',
  WEEE_answers6_2:'A) h下边黑线的高的大小不得小于0.3A或者1MM;',
  WEEE_answers6_3:'B)整个标识的高度不得小于7MM (即A=3.33MM);',
  WEEE_answers6_4:'C)若由于特殊原因无法在产品上进行标识，必须在产品包装或说明书上体现此标识;',
  WEEE_answers6_5:'D)该标识必须清晰、明显且不易磨损',
  WEEE_question7:'欧盟WEEE指令垃圾桶标识要求中，对于标识位置有什么特殊要求?',
  WEEE_answers7_1: '新WEEE指令要求产品在管控范围内的，需要贴WEEE标示，规格要求如下:',
  WEEE_answers7_2:'A)产品的标识必须张贴在明显易见的位置;',
  WEEE_answers7_3:'B)对于便携式产品应不借助工具就可去掉覆盖物看到标识，但是基于其他指令的健康及安全原因需要使用工具打开覆盖物的情形除外;',
  WEEE_answers7_4:'C)如因产品大小或功能限制而不能将标识印在产品上,需印在包裝、说明书或保修卡上;',



  PACKAGING_question1:'如果不注册德国包装法会有什么后果?',
  PACKAGING_answers1_1: '1、如果您不履行生产者的责任(包括但不限于在ZSVR注册、申报包装信息和向双元制系统支付申报费)，您将面临销售禁令和被监管机构最高罚款100,000欧元的风险。',
  PACKAGING_answers1_2: '2、2022 年7月1日之后，如果您通过欧盟电商平台向德国销售商品，欧盟电商平台必须检查您是否已在LUCID中注册。否则，欧盟电商平台将暂停不合规商品的销售。',
  PACKAGING_answers1_3: '3、注册LUCID后，即表示您向欧盟电商平台和公众声明，您已为在德国出售的商品包装履行了所有的生产者责任义务，包括签署系统注册协议、向双元制系统运营商支付系统注册费以及履行申报义务。',
  PACKAGING_answers1_4: '4、注册生产者名册为公开信息，旨在使任何人都能搜索特定的生产商、品牌、产地、LUCID 注册号或税号。以便检查生产商是否履行了相应义务。',
  PACKAGING_question2:'谁需要注册德国包装法?',
  PACKAGING_answers2_1:'如果您的商品具有任何类型的包装(包括配送至最终买家的二级包装)，则您会被视为生产者责任延伸中的“生产者”。生产者将需要为德国包装法负责，这边所说的生产者并不单指制造商，对于其他商品分类，简单来说，如果你满足以下任一选项，您就需要履行德国包装法义务:',
  PACKAGING_answers2_2: '①如果您在德国制造需 要符合生产者责任延伸要求的商品并在德国内销售;',
  PACKAGING_answers2_3:'②如果您向德 国进口需要符合生产者责任延伸要求的商品;',
  PACKAGING_answers2_4:'③如果您在德国销售 需要符合生产者责任延伸要求的商品，并且您并未在德国成立公司。这类型是跨境卖家最多符合的类型。',
  PACKAGING_question3:'包装的材料有哪些呢?',
  PACKAGING_answers3_1: '1.玻璃(无色或有色、无盖) ;',
  PACKAGING_answers3_2:'2.纸张、纸板;',
  PACKAGING_answers3_3:'3.黑色金属(带磁性，例如玻璃瓶盖或食物罐盖);',
  PACKAGING_answers3_4:'4.铝(非磁性，例如奶油管);',
  PACKAGING_answers3_5:'5.塑料(PET、PP、PE等;复合塑料);',
  PACKAGING_answers3_6:'6.其它复合包装: (1) 无法用手拉扯开的符合材料，(2) 每种类型的材料占重量的比例都不足95%， 以及，(3)其内容物不是饮料或食品;',
  PACKAGING_answers3_7:'7.其它材料(例如棉花、木材、天然软木、橡胶、拉菲丝、陶瓷)。',
  PACKAGING_answers3_8:'8.如果您在商品包装、填充材料或其它组件(如胶带、地址标签或包裹串)中使用了保鲜膜，其也算是包装法所规定的包装材料。',
  PACKAGING_question4: '自配送卖家也需要注册包装法吗?',
  PACKAGING_answers4_1: '自配送卖家只要在当地有销售，就需要遵守包装法条例，注册包装法。',
  PACKAGING_answers4_2:'跨境电商平台也会确认所有卖家均符合包装法规的要求，无论您使用的是平台物流还是卖家自配送物流。',
  PACKAGING_question5: '玩具产品的包装是否受该法监管?',
  PACKAGING_answers5_1:'只要使用了包装，不管是什么类型的产品，都是受这个法律规范的。',
  PACKAGING_question6:'包装法的免责声明是什么意思?',
  PACKAGING_answers6_1:'商品引入市场前，进口商需要注册，提交的数据是预估的。次年3月15日前，进口商应提交-份完整性声明，说明上-年实际使用了多少包装。如果去年的包装使用较少,那么这种说法可以免除。注意注册的时候是没有免检这回事的。即使只进口一个包装产品，也需要注册这个包装。',
  PACKAGING_question7:'包装法注册后需要印刷在标签上吗?',
  PACKAGING_answers7_1:'包装法没有强制要求印刷，(可印可不印) 为了保险起见，建议把回收标志印刷在纸箱标签上。',
  PACKAGING_question8:'所有产品的包装是否受该法监管?',
  PACKAGING_answers8_1: '也有例外，但-般来说，这些例外不适用于中国卖家在亚马逊销售的产品。比如农药瓶是个例外,因为瓶子被有毒物质污染了。',
  PACKAGING_question9:'是否有要求卖家在注册包装法后更换包装?',
  PACKAGING_answers9_1: '新的包装法没有对包装本身提出新的要求(如化学、物理和标签要求)。如果您的包装材料已经符合原包装法的要求,则无需更换包装材料,但需要完成注册。',
  PACKAGING_question10: '包装法的免责声明是什么意思?',
  PACKAGING_answers10_1: '在商品投放市场之前，进口商必须进行登记。所传送的数据已经确定，在次年3月15日之前，进口商必须申报完好无损，说明上一年实际使用了多少包装。如果上一年使用的包装较少，则可以免除申报。请注意，《包装法》也不例外。即使您只进口一种包装产品，您也必须注册《包装法》。',
  PACKAGING_question11:'注册《德国包装法》需要提供哪些信息?',
  PACKAGING_answers11_1:'营业执照、增值税税号、法人信息、电话、邮箱、品牌信息。',


  BATTERY_question1: '什么是德国电池法?',
  BATTERY_answers1_1:'德国电池法BattG将欧洲指令2006/66 / EC转化为国家法律，并规定了电池和蓄电池的回收处理。要求在德国市场上销售电池、蓄电池的卖家必须对已售出的电池进行回收处理。此法适用于所有类型的电池，无论其形状、大小、重量、材料成分或用途。',
  BATTERY_question2:'德国电池法有什么作用?',
  BATTERY_answers2_1:'用来规范德国境内所有电池、蓄电池的回收处理。因此，卖家有义务收回投放德国市场的电池和蓄电池，并与回收公司签合同，回收公司按照特定的环境标准处理废旧电池。',
  BATTERY_question3: '谁有注册义务?',
  BATTERY_answers3_1:'只要在德国销售产品，产品类目带有电池/可充电池类，卖家就必须要注册德国电池法；并承担产品投放到市场后所产生的废弃物回收责任。',
  BATTERY_question4: '哪些产品需要做电池法?',
  BATTERY_answers4_1:'电池、蓄电池品类（如：7号电池）;',
  BATTERY_answers4_2:'含电池的产品（如：配电池的电动玩具）;',
  BATTERY_answers4_3:'内置电池的产品（如：手机）;',
  BATTERY_answers4_4:'内电池法注册号标准格式',
  BATTERY_answers4_5:'电池法注册号标准格式',
  BATTERY_answers4_6:'电池法的注册号码格式为：Batt-Reg.-Nr. DE XXXXXXXX。',
  BATTERY_answers4_7:'注册号有效期为一个自然年，如：2022年注册成功，那2023年初就需续费。',
  BATTERY_question5: '卖家不注册德国电池法有什么严重后果?',
  BATTERY_answers5_1:'如果没有注册电池法、不回收处理废旧电池、没有定期申报销售数量，根据德国电池法，亚马逊官方公告和德国反不正当竞争法，会面临以下严重后果:',
  BATTERY_answers5_2:'1、删除Listing，下架商品链接;',
  BATTERY_answers5_3:'2、货物到德国时，被海关扣押;',
  BATTERY_answers5_4:'3、没收卖家在德国的所有盈利;',
  BATTERY_answers5_5:'4、最高罚款10万欧元;',
  BATTERY_question6: '跨境电商应该卖家如何应对电池法?',
  BATTERY_answers6_1: '如果没有注册电池法、不回收处理废旧电池、没有定期申报销售数量，根据德国电池法，亚马逊官方公告和德国反不正当竞争法，会面临以下严重后果:',
  BATTERY_answers6_2: '1.在投放市场前完成电池法的注册，注册时需要提供关于回收的相关信息;',
  BATTERY_answers6_3: '2.拿到证书后，需要将电池法回收标识需印在产品本身上，并且是永久清晰可见且可读的;',
  BATTERY_answers6_4: '3.回收废旧电池并按法规规定进行处置;',
  BATTERY_answers6_5: '4.定期向官方报告申报;',
  BATTERY_question7: '电池注册号是否可以注册一个，多个店铺通用?',
  BATTERY_answers7_1:'电池注册号是和店铺对应的。一个店铺只有一个注册号，一个注册号，也只能对应一个店铺。 一个公司下有多个店铺，就算是卖的产品一样，每个店铺也需要单独注册电池法。',
  BATTERY_question8: '电池法产品外包装标识要求?',
  BATTERY_answers8_1:'新电池法指令要求产品在管控范围内的，需要贴电池法标示，规格要求如下:',
  BATTERY_answers8_2:'A) H下边黑线的高的大小不得小于0.3A或者1MM;',
  BATTERY_answers8_3: 'B)整个标识的高度不得小于7MM (即A=3.33MM);',
  BATTERY_answers8_4: 'C)若由于特殊原因无法在产品上进行标识，必须在产品包装或说明书上体现此标识;',
  BATTERY_answers8_5:  'D)该标识必须清晰、明显且不易磨损;',
  BATTERY_question9:'欧盟电池法指令垃圾桶标识要求中，对于标识位置有什么特殊要求?',
  BATTERY_answers9_1:'A)产品的标识必须张贴在明显易见的位置;',
  BATTERY_answers9_2:'B)对于便携式产品，应不借助工具就可去掉覆盖物看到标识，但是基于其他指令的健康及安全原因需要使用工具打开覆盖物的情形除外;',
  BATTERY_answers9_3:'C)如因产品大小或功能限制而不能将标识印在产品上，需印在包裝、说明书或保修卡上;',
  BATTERY_answers9_4:'这些规定确保电池和蓄电池的标签易于识别和理解，并便于消费者适当处置。',


  EPR_question1: '什么是EPR?',
EPR_answers1_1:'EPR是生产者责任延伸，是一项环境政策，要求生产者对其在市场.上所推出商品的整个生命周期负责，即从商品设计开始到商品生命周期结束( 包括废弃物收集和处理)。根据生产者责任延伸法规的规定，责任主体必须在整个商品生命周期内降低其商品对环境产生的影响。',
EPR_question2: '只有德国和法国才有EPR吗?',
EPR_answers2_1:'并不是。绝大多数的欧洲国家，不止欧盟成员国，一直以来都有相应的法规法案管控EPR。对于欧洲跨境卖家来说，目前德国、法国、西班牙、奥地利是强制性要求注册的，但是未来英国、意大利、荷兰等国家也会有要求，具体时间将持续更进。',
EPR_question3: '如果我是自配送卖家，需要注册EPR吗?',
EPR_answers3_1: '需要的，亚马逊必须确认所有卖家均符合EPR生产者责任延伸的要求，无论您使用的是使用FBA还是卖家自发货。',
EPR_question4: '谁需要注册EPR呢?',
EPR_answers4_1:'从原定义上来讲，EPR是针对“生产者”的。但该“生产者”并非常规所理解的制造商或生产厂家，以下三种，需要注册EPR',
EPR_answers4_2:'当地制造商：如果您在相应的国家/地区制造需要符合生产者责任延伸要求的商品;',
EPR_answers4_3:'当地进口商：如果您向相应的国家/地区进口需要符合生产者责任延伸要求的商品;',
EPR_answers4_4:'通过平台销售到当地：如果您在相应的国家/地区销售需要符合生产者责任延伸要求的商品，并且您并未在该国家/地区成立公司。',
EPR_answers4_5:'基本所有卖家都属于第三种生产者。',
EPR_question5:'EPR注册号是统一的吗?',
EPR_answers5_1:'EPR注册号不是统一的，EPR在不同的国家，有不同的商品分类，有不同的注册要求、注册流程以及授权机构，下来的注册号也不一样。',
EPR_question6: '哪些产品需要注册EPR?',
EPR_answers6_1:'德国：包装、电子电气设备（WEEE）、电池。',
EPR_answers6_2:'法国：包装、电子电气设备（WEEE）、电池、家具、纺织品、纸类、轮胎等。',
EPR_answers6_3:'奥地利：包装、电子电气设备（WEEE）、电池、以及一次性塑料等。',
EPR_answers6_4:'西班牙：包装、电器和电子设备（EEE）、电池、润滑油和工业用油以及轮胎等。',
EPR_question7: '不注册有什么后果?',
EPR_answers7_1: '如果在欧盟国家/地区进行销售不注册EPR，亚马逊将有权下架不符产品。',
EPR_answers7_2: '如果不做法国EPR注册申报，法国当局有权要求跨境平台通知在其平台上销售的跨境卖家注册EPR，在取得EPR证书及UIN号码之前，责令卖家停止销售。',
EPR_answers7_3: '若卖家违反法国环境法规定的其他义务，将受到以下处罚：',
EPR_answers7_4: '1、最高罚款100万欧元;',
EPR_answers7_5: '2、在法国收入均被认为非法所得，将被没收;',
EPR_answers7_6: '3、缴纳罚款之前不得进行任何销售。',

AUTH_Agent_1: '授权代理认证信息',
AUTH_Agent_productname: '产品名称',
AUTH_Agent_registration_time: '登记时间',
AUTH_Agent_trademark: '品牌',
AUTH_Agent_model: '型号',
AUTH_Agent_platform_product_uniqure_code: '平台/产品识别码',
AUTH_Agent_manufacturer: '制造商',
AUTH_Agent_Applicant_Company: '申请主体公司',

DOC_Certification:'DoC认证',
DOC_Instruction:'指令',
DOC_Standard:'标准',

VALID: '有效',
INVALID: '无效',
VIEWALL: '查看全部',
FREEZE: '冻结',
DATAPROVIDE: '数据由J&P Accountants提供',
ADD_product_tips: '请及时添加产品信息',
UNUSAL_MSG: '等待授权注册人提供',
UNUSAL_DOC_MSG: 'DOC未生效'
}
