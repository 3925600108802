// index.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from './de'
import zh from './zh'

const resources = {
  de: {
    translation: de
  },
  zh: {
    translation: zh
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'de',
  // fallbackLng: 'zh',
  // interpolation: {
  //   escapeValue: false
  // },
  // debug: true
}, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key'); // -> same as i18next.t
  })

export default i18n

